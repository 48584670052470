import React, { createRef, useEffect, useRef, useState } from "react";

// ASSETS
import ArrowLeft from "assets/svg/ArrowDown.svg";
import facebookIcon from "assets/svg/IconFacebook.svg";
import linkedInIcon from "assets/svg/iconsLinkedIn.svg";
import shareIcon from "assets/svg/ShareIcon.svg";
import ArrowUpIcon from "assets/svg/RightRedIcon.svg";
import coverPlaceholder from "assets/img/cover.placeholder.svg";
import iconPlaceholder from "assets/img/icon.placeholder.svg";
import RedTriangleIcon from "assets/svg/RedTriangleIcon.svg";
import RedStopPlay from "assets/svg/RedStopPlay.svg";

// STYLES
import {
  AudioButton,
  AudioButtonWrapper,
  BackOnTopImg,
  BackOnTopWrapper,
  BackToGuidesButton,
  BackToGuidesImage,
  BackToGuidesWrapper,
  Body,
  CenterCardTitle,
  ContentCard,
  CopyToClipboardTooltip,
  CopyToClipboardWrapper,
  FirstColumn,
  HeaderCell,
  HeaderGuide,
  HeaderImage,
  HeaderImageWrapper,
  IconStyled,
  ImgStyled,
  InsideFirstColumn,
  InsideThirdColumn,
  LanguageButton,
  LeftCard,
  LeftCardChapter,
  LeftCardTitle,
  OtherChapters,
  OtherGuidesCard,
  ProgressBar,
  RedCircle,
  RedCircleWrapper,
  ScrollTargetWrapper,
  SecondColumn,
  SingleGuideContainer,
  SkeletonWrapperChapter,
  SkeletonWrapperHeader,
  SkeletonWrapperOtherChapter,
  SocialMediaCard,
  SocialMediaIcon,
  SocialMediaIcons,
  ThirdColumn,
  TitleWrapper,
  WhiteCard,
} from "./SingleGuideStyled";

// LIBRARIES
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

// MISC
import modRewrite from "../../../locales/modRewrite";
import { TextType } from "components/Atoms/CustomText/CustomTextModel";
import { content } from "constants/mocks/GuideData";
import { BORDER_RADIUS, COLORS, DEVICE_TYPE } from "constants/variables";
import { CardType } from "components/Atoms/GuideCardAtom/GuideCardAtomModel";

// REDUX
import { selectLanguage } from "slices/LanguageSlice/languageSlice";
import { useSelector } from "react-redux";

// COMPONENTS
import CustomText from "components/Atoms/CustomText/CustomText";
import GuideCardAtom from "components/Atoms/GuideCardAtom/GuideCardAtom";
import guideService from "../../../services/guideService";

import { baseUrl } from "../../../services/base";
import StrapiTreeText from "components/Atoms/StrapiTreeText/StrapiTreeText";

const SingleGuide = () => {
  // PROPERTIES
  const { rgxId } = useParams();
  const tmpIds: any = rgxId.split("-");
  const guideId = tmpIds.pop();
  const paramSectionId: any = tmpIds.pop();

  // LIBRARY CONSTANTS
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: DEVICE_TYPE.mobileHeader });
  const navigate = useNavigate();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [play, setPlay] = useState<boolean>(false);
  const userLanguage = useSelector(selectLanguage);

  // STATE CONSTANTS

  const [selectedTab, setSelectedTab] = useState(null);
  const [isContentReady, setIsContentReady] = useState(false);
  const [activeChapter, setActiveChapter] = useState(null);
  const [guide, setGuide] = useState<any>(null);
  const [chapters, setChapters] = useState<any>([]);
  const [randomGuides, setRandomGuides] = useState<any[]>([]);
  const contentRefs = useRef([]);
  const [copied, setCopied] = useState(false);
  const [audioGuide, setAudioGuide] = useState<any>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [innerLocale, setInnerLocale] = useState<string | "ro">("en");
  const [locales, setLocales] = useState<any[]>([]);
  const headerImageWrapperRef = useRef(null);
  const skeletonCount = 3;

  useEffect(() => {
    const anchor =
      contentRefs?.current[parseInt(activeChapter)]?.current.getAttribute("id");
    if (
      isContentReady &&
      contentRefs.current[parseInt(activeChapter)]?.current
    ) {
      contentRefs.current[parseInt(activeChapter)].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      navigate(`#${anchor}`);
    }

    // eslint-disable-next-line
  }, [activeChapter, isContentReady, contentRefs]);

  useEffect(() => {
    async function callMe(userLanguage) {
      try {
        const localesResponse = await guideService.getGuideLocales(guideId);

        const allLocales = localesResponse?.data.attributes.localizations.data;
        const currentLocale = localesResponse?.data.attributes.locale;
        //const filteredLocales = allLocales

        setLocales(allLocales);
        setInnerLocale(currentLocale);
      } catch (e) {
        console.error(e);
      }
    }
    if (guideId) {
      callMe(userLanguage);
    }
  }, [guideId, userLanguage]);

  useEffect(() => {
    (async () => {
      try {
        const currentGuide: any = await guideService.getGuide(guideId);
        // console.log("crt guide", currentGuide);
        if (currentGuide.data.attributes.sections.data !== null) {
          currentGuide.data.attributes.sections.data.sort(
            (a, b) => a.order - b.order
          );
          currentGuide.data.attributes.chapters.data.sort((a, b) => {
            return a.attributes.chapter_number - b.attributes.chapter_number;
          })
          currentGuide.data.attributes.chapters.data.forEach((chap: any) => {
            const sections =
              chap.attributes.sections.data !== undefined
                ? chap.attributes.sections.data.map((el: any) => el.id)
                : [];
            const item =
              currentGuide.data.attributes.sections.data !== null
                ? currentGuide.data.attributes.sections.data.find((el: any) =>
                    sections.includes(el.id)
                  )
                : null;
            if (currentGuide.data.attributes.sections.data) {
              setSelectedTab(
                currentGuide.data.attributes.sections.data[0]["id"]
              );
            }
            if (item) {
              if (item["chapters"] === undefined) {
                item["chapters"] = [];
              }
              item["chapters"].push(chap);
            }
          });
        }
        setGuide(currentGuide.data.attributes);
        if (isNaN(paramSectionId)) {
          setSelectedTab(currentGuide.data.attributes.sections.data[0].id);
        } else {
          setSelectedTab(paramSectionId);
        }
        const guides: any = await guideService.getGuides(innerLocale);

        setRandomGuides(
          guides.data
            .filter((el: any) => el.id !== parseInt(guideId))
            .slice()
            .sort(() => 0.5 - Math.random())
            .slice(0, 3)
        );
      } catch (err) {}
    })();
  }, [guideId, innerLocale]);

  useEffect(() => {

    if (guideId) {
      (async () => {
        console.log("CHANGED",guideId,selectedTab);
        if (!selectedTab) {
          return;
        }
        try {
          const audioData = await guideService.getAudioGuide(
            guideId,
            selectedTab,
            innerLocale
          );

          setPlay(false);
          if (audioData.length > 0) {
            setAudioGuide(audioData[0]);
          }
        } catch (error) {
          console.error("Error fetching audio guide:", error);
        }
      })();
    }
  }, [guideId, selectedTab]);

  useEffect(() => {
    if (audioGuide && selectedTab) {
      const matchedAudio = `${baseUrl}${audioGuide.attributes.audio?.data?.attributes?.url}`;
      setAudioUrl(matchedAudio);
    }
  }, [audioGuide, selectedTab]);

  useEffect(() => {
    if (selectedTab && guide) {
      const crtSection = guide.sections.data.find(
        (el: any) => el.id === parseInt(selectedTab)
      );
      const tmpChapters = crtSection?.chapters || [];
      let anchor = "";
      if (tmpChapters.length > 0) {
        const tmpAnchor = window.location.href.split("#");

        if (tmpAnchor.length > 1) {
          anchor = "#" + tmpAnchor[1];
        }

        navigate(
          `/ghiduri/${modRewrite(guide.title)}-${modRewrite(
            crtSection.attributes.title
          )}-${selectedTab}-${guideId}${anchor}`
        );
      }
      setChapters(tmpChapters ? tmpChapters : []);
      if (tmpChapters.length > 0) {
        contentRefs.current = guide.sections.data
          .find((el: any) => el.id === parseInt(selectedTab))
          .chapters.map(
            (_, index) => contentRefs.current[index] ?? createRef()
          );

        if (anchor.length === 0) {
          setActiveChapter(0);
        } else {
          setActiveChapter(
            tmpChapters.indexOf(
              tmpChapters.find(
                (el: any) => el.id === parseInt(anchor.split("-").pop())
              )
            )
          );
        }
      }
    }
  }, [selectedTab, guide]);

  useEffect(() => {
    if (content.length > 0) {
      setIsContentReady(true);
    }
  }, [contentRefs.current]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {});
      },
      { rootMargin: "0px", threshold: 0.1 }
    );

    contentRefs.current.forEach((ref, index) => {
      if (ref.current) {
        ref.current.setAttribute(
          "data-chapter-id",
          content[index] ? content[index].id.toString() : 0
        );
        observer.observe(ref.current);
      }
    });

    return () => {
      contentRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [contentRefs.current]);

  useEffect(() => {
    setProgress(0);
  }, [selectedTab]);

  useEffect(() => {
    const updateProgress = () => {
      if (audioRef.current) {
        const currentTime = audioRef.current.currentTime;
        const duration = audioRef.current.duration;
        if (duration) {
          setProgress((currentTime / duration) * 100);
        }
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", updateProgress);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, [audioRef.current]);

  // API REQUESTS

  // LIFE CYCLE

  // EVENT HANDLERS
  const scrollToChapter = (index) => {
    setActiveChapter(index);
  };

  const scrollToTop = () => {
    if (headerImageWrapperRef.current) {
      headerImageWrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy URL: ", err));
  };

  const playAudio = () => {
    if (audioRef.current && typeof audioRef.current.play === "function") {
      if (!play) {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      } else {
        audioRef.current.pause();
      }
      setPlay(!play);
    } else {
      console.error("Audio element is not initialized:", audioRef.current);
    }
  };

  const handleProgressChange = (e) => {
    if (audioRef.current) {
      const newTime = (e.target.value / 100) * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setProgress(e.target.value);
    }
  };

  return (
    <SingleGuideContainer>
      <HeaderImageWrapper ref={headerImageWrapperRef}>
        <HeaderImage
          src={
            guide && guide.cover.data !== null
              ? `${baseUrl}${guide.cover.data.attributes.url}`
              : coverPlaceholder
          }
          alt={"coverGuideImage"}
        />
      </HeaderImageWrapper>
      <HeaderGuide>
        {guide?.sections?.data !== null &&
        guide?.sections?.data !== undefined ? (
          guide?.sections?.data.map((tab, index) => (
            <HeaderCell
              tabIndex={0}
              key={index}
              isSelected={parseInt(selectedTab) === tab.id}
              onClick={() => {
                setSelectedTab(tab.id);
                scrollToChapter(0);
              }}
            >
              <CustomText text={tab.attributes.title} type={TextType.H7fix} />
            </HeaderCell>
          ))
        ) : (
          <SkeletonWrapperHeader>
            {[...Array(skeletonCount)].map((_, index) => (
              <Skeleton
                key={index}
                width={100}
                count={2}
                baseColor={COLORS.Solitude}
                highlightColor={COLORS.WhiteSmoke}
              />
            ))}
          </SkeletonWrapperHeader>
        )}
      </HeaderGuide>
      <Body>
        <FirstColumn>
          <InsideFirstColumn>
            <BackToGuidesWrapper onClick={() => navigate("/ghiduri")}>
              <BackToGuidesImage src={ArrowLeft} alt="backIcon" />
              <BackToGuidesButton>
                <CustomText
                  text={t("view.singleGuides.backButton")}
                  type={TextType.H4fix}
                  color={COLORS.Paua}
                />
              </BackToGuidesButton>
            </BackToGuidesWrapper>
            <TitleWrapper>
              {guide !== null ? (
                   <CustomText
                  text={`${['en','ro'].includes(innerLocale) ? t("view.singleGuides.guide") : ""} ${
                    guide && guide.title.toLowerCase()
                  }`}
                  type={!isMobile ? TextType.H1custom : TextType.H2}
                />
              ) : (
                <Skeleton
                  width={"100%"}
                  height={"3rem"}
                  borderRadius={BORDER_RADIUS.radius21}
                  count={1}
                  baseColor={COLORS.WhiteSmoke}
                  highlightColor={COLORS.Solitude}
                />
              )}
            </TitleWrapper>
            {locales.length > 0 &&
            <WhiteCard>
              {locales.map((el, index) => {
                const localeLabels = {
                  ro: "Română",
                  en: "English",
                  uk: "Українська",
                };
                return (
                  <LanguageButton href={`/ghiduri/${el.id}`} key={index}>
                    {localeLabels[el.attributes.locale] || el.attributes.locale}
                  </LanguageButton>
                );
              })}
            </WhiteCard>}
            {audioUrl ? ( <AudioButtonWrapper>
              <audio ref={audioRef} src={audioUrl} />


                <>
                  <AudioButton onClick={playAudio}>
                    <IconStyled
                      src={play ? RedStopPlay : RedTriangleIcon}
                      alt="Play Icon"
                    />
                    {play
                      ? t("view.singleGuides.stop")
                      : t("view.singleGuides.listen")}
                  </AudioButton>
                  <ProgressBar
                    type="range"
                    min="0"
                    max="100"
                    value={progress}
                    onChange={handleProgressChange}
                  />
                </>
            </AudioButtonWrapper>)
                : ""}
            <LeftCard>
              <LeftCardTitle>
                <CustomText
                  text={t("view.singleGuides.chapters")}
                  type={TextType.H3}
                />
              </LeftCardTitle>
              {chapters.length > 0 ? (
                chapters.map((chapter, index) => (
                  <LeftCardChapter
                    key={index}
                    onClick={() => scrollToChapter(index)}
                    tabIndex={0}
                  >
                    <RedCircleWrapper>
                      <RedCircle />
                    </RedCircleWrapper>
                    <CustomText
                      text={chapter.attributes.title}
                      type={
                        index === parseInt(activeChapter)
                          ? TextType.P2fixbold
                          : TextType.P2fix
                      }
                    />
                  </LeftCardChapter>
                ))
              ) : (
                <LeftCardChapter>
                  <RedCircleWrapper>
                    <RedCircle />
                  </RedCircleWrapper>
                  <SkeletonWrapperChapter>
                    <Skeleton
                      width={"100%"}
                      count={1}
                      baseColor={COLORS.Solitude}
                      highlightColor={COLORS.WhiteSmoke}
                    />
                  </SkeletonWrapperChapter>
                </LeftCardChapter>
              )}
            </LeftCard>
          </InsideFirstColumn>
        </FirstColumn>
        <SecondColumn>
          {chapters.length > 0 ? (
            chapters.map((chapter, index: number) => (
              <ScrollTargetWrapper
                ref={contentRefs.current[index]}
                key={index}
                id={modRewrite(chapter.attributes.title, chapter.id)}
              >
                <ContentCard tabIndex={0}>
                  <CenterCardTitle>
                    <CustomText
                      text={chapter.attributes.title}
                      type={TextType.H3}
                    />
                  </CenterCardTitle>
                  {chapter.attributes.content && (
                    <StrapiTreeText text={chapter.attributes.content} />
                  )}
                </ContentCard>
              </ScrollTargetWrapper>
            ))
          ) : (
            <>
              <ContentCard tabIndex={0}>
                <CenterCardTitle>
                  <Skeleton
                    width={"100%"}
                    count={1}
                    baseColor={COLORS.Solitude}
                    highlightColor={COLORS.WhiteSmoke}
                  />
                </CenterCardTitle>
                <Skeleton
                  width={"100%"}
                  count={15}
                  baseColor={COLORS.Solitude}
                  highlightColor={COLORS.WhiteSmoke}
                />
              </ContentCard>
            </>
          )}
        </SecondColumn>
        <ThirdColumn>
          <InsideThirdColumn>
            <SocialMediaCard>
              <CustomText text={"SHARE"} type={TextType.H4fix} />
              <SocialMediaIcons biggerCursor={true}>
                <FacebookShareButton url={window.location.href}>
                  <SocialMediaIcon src={facebookIcon} alt="facebookIcon" />
                </FacebookShareButton>
                <LinkedinShareButton url={window.location.href}>
                  <SocialMediaIcon src={linkedInIcon} alt="linkedInIcon" />
                </LinkedinShareButton>
                <CopyToClipboardWrapper onClick={handleCopyToClipboard}>
                  <SocialMediaIcon src={shareIcon} alt="shareIcon" />
                  {copied && (
                    <CopyToClipboardTooltip>
                      <CustomText
                        text="Link-ul a fost copiat in clipboard"
                        type={TextType.P2}
                        color={COLORS.BlackRussian}
                      />
                    </CopyToClipboardTooltip>
                  )}
                </CopyToClipboardWrapper>
              </SocialMediaIcons>
            </SocialMediaCard>
            <OtherGuidesCard>
              <CustomText
                text={t("view.singleGuides.otherGuides")}
                type={TextType.H3fix}
              />
              {randomGuides.length > 0 ? (
                randomGuides.map((el: any, index: number) => {
                  return (
                    <OtherChapters
                      key={index}
                      tabIndex={0}
                      onClick={() => {
                        navigate(
                          `/ghiduri/${modRewrite(el.attributes.title, el.id)}`
                        );
                      }}
                    >
                      <GuideCardAtom
                        icon={
                          <ImgStyled
                            src={
                              el.attributes.icon.data !== null
                                ? `${baseUrl}${el.attributes.icon.data.attributes.url}`
                                : iconPlaceholder
                            }
                          />
                        }
                        cardType={CardType.SingleGuideStyle}
                        width={"80px"}
                        height={"80px"}
                        handleCardPress={() => false}
                      />
                      <CustomText
                        text={el.attributes.title}
                        type={TextType.H5fix}
                      />
                    </OtherChapters>
                  );
                })
              ) : (
                <>
                  {[...Array(skeletonCount)].map((_, index) => (
                    <SkeletonWrapperOtherChapter key={index}>
                      <Skeleton
                        width={"80px"}
                        height={"80px"}
                        borderRadius={BORDER_RADIUS.radius16}
                        baseColor={COLORS.Solitude}
                        highlightColor={COLORS.WhiteSmoke}
                      />
                      <SkeletonWrapperChapter>
                        <Skeleton
                          count={1}
                          baseColor={COLORS.Solitude}
                          highlightColor={COLORS.WhiteSmoke}
                        />
                      </SkeletonWrapperChapter>
                    </SkeletonWrapperOtherChapter>
                  ))}
                </>
              )}
            </OtherGuidesCard>
          </InsideThirdColumn>
        </ThirdColumn>
      </Body>
      {isMobile && (
        <BackOnTopWrapper onClick={scrollToTop}>
          <BackOnTopImg src={ArrowUpIcon} alt="ArrowUpIcon" />
        </BackOnTopWrapper>
      )}
    </SingleGuideContainer>
  );
};

export default SingleGuide;
