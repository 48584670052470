import { AxiosInstance } from "axios";
import axiosBase from "./base";
import { store } from "store/store";

class GuideService {
  base: AxiosInstance;

  constructor() {
    this.base = axiosBase.base;
  }

  // Clients
  getGuidesCategories(): Promise<any[]> {
    const state = store.getState();
    const language = state.language.language;

    return new Promise(async (resolve, reject) => {
      this.base
        .get(`/api/guides-categories?locale=${language}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getGuideLocales(id: string): Promise<any> {
    // const state = store.getState();
    // const language = state.language.language;

    return new Promise(async (resolve, reject) => {
      this.base
          .get(`/api/guides/${id}?populate[localizations][fields][0]=locale&fields[0]=locale`)
          .then(({data}) => {
            return resolve(data);
          })
          .catch((err) => reject(err));
    });
  }

  getGuides(locale: string | undefined = undefined): Promise<any[]> {

    let language = locale
    if (!locale) {
      const state = store.getState();
      language = state.language.language
    }

    return new Promise(async (resolve, reject) => {
      this.base
        .get(
          `/api/guides/?populate[icon]=icon&populate[guides_category]=guides_category&pagination[pageSize]=100&sort[0]=title:asc&locale=${language}`
        )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }

  getGuide(guideId: string): Promise<any[]> {
    const state = store.getState();
    const language = state.language.language;

    return new Promise(async (resolve, reject) => {
      this.base
        .get(
          `/api/guides/${guideId}/?populate[sections]=*&populate[cover]=*&populate[icon]=icon&populate[chapters][populate][sections][populate]=id&locale=${language}`
        )
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }
  getAudioGuide(guideId: string, sectionId: string, innerLocale: string): Promise<any[]> {
    const state = store.getState();
    const language = state.language.language;

    return new Promise(async (resolve, reject) => {
      this.base
        .get(
          `https://app.dsu.mai.gov.ro/api/audio-guides?filters[guide][id][$eq]=${guideId}&filters[section][id][$eq]=${sectionId}&populate[guide]=*&populate[section]=*&populate[audio]=*&locale=${innerLocale || language}`
        )
        .then(({ data }) => {
          return resolve(data.data);
        })
        .catch((err) => reject(err));
    });
  }
  getDefibrlators(): Promise<any[]> {
    const state = store.getState();
    const language = state.language.language;

    return new Promise(async (resolve, reject) => {
      this.base
        .get(`/api/get-scheduled&locale=${language}`)
        .then(({ data }) => {
          return resolve(data);
        })
        .catch((err) => reject(err));
    });
  }
}

const guideService = new GuideService();
export default guideService;
